Date.prototype.formatDate = function(format) {    
    var date = this;    
    if (!format) {
        format = "MM/dd/yyyy";
    }
    var month = date.getMonth() + 1;    
    var year = date.getFullYear();         
    format = format.replace("MM",month.toString().padL(2,"0"));             
    if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2, 2));
    }
    format = format.replace("dd",date.getDate().toString().padL(2,"0"));     
    var hours = date.getHours();           
    if (format.indexOf("t") > -1) {       
        if (hours > 11) {
            format = format.replace("t", "pm")
        } else {
            format = format.replace("t", "am")
        }
    }
    if (format.indexOf("HH") > -1) {
        format = format.replace("HH", hours.toString().padL(2, "0"));
    }
    if (format.indexOf("hh") > -1) {
        if (hours > 12) {
            hours - 12;
        }
        if (hours == 0) {
            hours = 12;
        }
        format = format.replace("hh", hours.toString().padL(2, "0"));
    }
    if (format.indexOf("mm") > -1) {
        format = format.replace("mm", date.getMinutes().toString().padL(2, "0"));
    }
    if (format.indexOf("ss") > -1) {
        format = format.replace("ss", date.getSeconds().toString().padL(2, "0"));
    }
    return format;
}

function IsDateValid(year, month, day) {
    year = typeof(year) == 'string' ? parseInt(year, 10) : year;
    month = typeof(month) == 'string' ? parseInt(month, 10) : month;
    day = typeof(day) == 'string' ? parseInt(day, 10) : day;
    year = (year < 1000) ? year + 1900 : year;
    month = month - 1; // javascript month range : 0- 11
    var tempDate = new Date(year, month, day);
    if ((tempDate.getFullYear() == year) && (month == tempDate.getMonth()) && (day == tempDate.getDate())) {
        return true;
    }
    return false;
}

String.repeat = function(chr,count) {        
    var str = "";     
    for (var x = 0; x < count; x++) {
        str += chr;
    }
    return str;
}

String.prototype.padL = function(width,pad) {    
    if (!width || width < 1) {
        return this;
    }
    if (!pad) {
        pad = " ";
    }
    var length = width - this.length;
    if (length < 1) {
        return this.substr(0, width);
    }
    return (String.repeat(pad,length) + this).substr(0,width);    
}

String.prototype.padR = function(width, pad) {
    if (!width || width < 1) {
        return this;
    }
    if (!pad) {
        pad = " ";
    }
    var length = width - this.length;
    if (length < 1) {
        this.substr(0, width);
    }
    return (this + String.repeat(pad, length)).substr(0, width);
}

String.prototype.startsWith = function(str) {
    return this.lastIndexOf(str, 0) === 0;
} 

function trim(s)
{
    var l = 0;
    var r = s.length - 1;
    while (l < s.length && s[l] == ' ') {
        l++;
    }
    while (r > l && s[r] == ' ') {
        r -= 1;
    }
    return s.substring(l, r+1);
}

function ltrim(s) {
    var l = 0;
    while (l < s.length && s[l] == ' ') {
        l++;
    }
    return s.substring(l, s.length);
}

function rtrim(s) {
    var r = s.length - 1;
    while (r > 0 && s[r] == ' ') {
        r -= 1;
    }
    return s.substring(0, r+1);
}

function IsNullOrWhitespace(str) {
    if (typeof (str) === 'undefined') {
        return true;
    } else if (str === null) {
        return true;
    } else if (trim(str.toString()).length <= 0) {
        return true;
    } else {
        return false;
    }
}